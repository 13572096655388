import React, { createContext, Component } from "react";
import { auth } from "../firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null,
    admin: false,
  };

  componentDidMount = () =>
    auth.onAuthStateChanged(async (user) =>
      auth.isAdmin().then(async (admin) => this.setState({ user, admin }))
    );

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
