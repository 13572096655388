import React from "react";
import { Menu, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import paths from "../../paths.json";

const MusicAdminEditMenu = ({
  id,
  name,
  handleNewSave,
  handleDeleteStation,
}) => (
  <Menu secondary stackable>
    <Menu.Item>
      <Header as="h2" color="blue" content={name} />
    </Menu.Item>
    <Menu.Item>
      <Button
        content="Back"
        icon="chevron left"
        as={Link}
        to={paths.musicAdmin}
      />
    </Menu.Item>
    {id === "new" ? (
      <Menu.Item position="right">
        <Button
          icon="save"
          // basic
          content="Save"
          color="orange"
          onClick={handleNewSave}
        />
      </Menu.Item>
    ) : (
      <Menu.Item position="right">
        <Button
          icon="x"
          content="Delete"
          color="red"
          onClick={handleDeleteStation}
        />
      </Menu.Item>
    )}
  </Menu>
);

export default MusicAdminEditMenu;
