import { auth } from "./firebase";

export const signIn = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

export const signOut = () => auth.signOut();

export const passwordReset = (email) => auth.sendPasswordResetEmail(email);

export const updatePassword = (password) =>
  auth.currentUser.updatePassword(password);

export const isAdmin = async () => {
  if (auth.currentUser) {
    return auth.currentUser
      .getIdTokenResult()
      .then((idToken) => (!!idToken.claims.admin ? true : false));
  }
  return Promise.resolve(false);
};

export const onAuthStateChanged = (a, b, c) => auth.onAuthStateChanged(a, b, c);
