import React, { useContext } from "react";

import { UserContext } from "../../providers/UserProvider";
import AdminToolList from "./AdminToolsList";
import PermissionsError from "../../components/PermissionsError";

const Home = () => {
  const { admin } = useContext(UserContext);

  return (
    <>
      {admin && <AdminToolList />}
      {!admin && <PermissionsError />}
    </>
  );
};

export default Home;
