import React from "react";
import {
  Segment,
  Header,
  Form,
  Input,
  TextArea,
  Button,
} from "semantic-ui-react";

const StationEditForm = ({
  id,
  languages,
  allGenres,
  stationData,
  handleInputChange,
  handleLanguageSelection,
  handleGenreSelection,
  handleSave,
}) => {
  const {
    loading,
    name,
    description,
    languageId,
    genres,
    website,
    logo,
  } = stationData;
  const validData = () => {
    if (
      name &&
      description &&
      languageId &&
      genres.length > 0 &&
      website &&
      logo
    ) {
      return true;
    }
    return false;
  };
  return (
    <Segment>
      <Header as="h3" content="Station Data" />
      <Form loading={loading}>
        <Form.Field
          control={Input}
          label="Name"
          placeholder="Station Name..."
          value={name}
          name="name"
          onChange={handleInputChange}
        />
        <Form.Field
          control={TextArea}
          label="Description"
          value={description}
          name="description"
          placeholder="Station Description..."
          onChange={handleInputChange}
        />
        <Form.Select
          search
          selection
          label="Language"
          value={languageId}
          name="languageId"
          placeholder="Language..."
          loading={languages.loading}
          options={languages.data}
          onChange={handleLanguageSelection}
        />
        <Form.Select
          search
          selection
          multiple
          clearable
          label="Genres"
          value={genres}
          name="genres"
          placeholder="Genres..."
          loading={allGenres.loading}
          options={allGenres.data}
          onChange={handleGenreSelection}
        />
        <Form.Field
          control={Input}
          label="Website"
          placeholder="https://..."
          value={website}
          name="website"
          onChange={handleInputChange}
        />
        <Form.Field
          control={Input}
          label="Logo"
          placeholder="Station Logo..."
          value={logo}
          name="logo"
          onChange={handleInputChange}
        />
        {id !== "new" && (
          <Button
            basic
            icon="save"
            content="Save Data"
            color="orange"
            onClick={handleSave}
            disabled={!validData()}
          />
        )}
      </Form>
    </Segment>
  );
};

export default StationEditForm;
