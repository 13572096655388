import React, { useState, useContext } from "react";
import { Grid, Segment, Form, Button, Message } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";

import { UserContext } from "./../providers/UserProvider";
import { auth } from "./../firebase";
import paths from "./../paths.json";

const Login = ({ location }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);

  const handleSubmit = (event, email, password) => {
    event.preventDefault();
    auth.signIn(email, password).catch((err) => {
      setError("Error signing in");
      console.error("Error Signing In with email and password", err);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  return !user ? (
    <Grid textAlign="center">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          <Form
            size="large"
            onSubmit={(event) => handleSubmit(event, email, password)}
          >
            <Form.Input
              value={email}
              name="email"
              onChange={(event) => handleChange(event)}
              type="email"
              icon="mail"
              iconPosition="left"
              placeholder="Email Address"
            />
            <Form.Input
              icon="lock"
              iconPosition="left"
              value={password}
              name="password"
              onChange={(event) => handleChange(event)}
              placeholder="Password"
              type="password"
            />

            <Button
              type="submit"
              color="orange"
              fluid
              size="large"
              disabled={email.length === 0 || password.length === 0}
            >
              Login
            </Button>
            {error && <Message negative>{error}</Message>}
          </Form>
          <Button
            style={{ marginTop: "1em" }}
            fluid
            basic
            as={Link}
            to={`${paths.resetPassword}`}
            content="Forgot Password?"
          />
        </Segment>
      </Grid.Column>
    </Grid>
  ) : (
    <Redirect to={location.state ? location.state.previous : paths.home} />
  );
};

export default Login;
