import { db } from "./firebase";

const stationsPath = "/stations";

export const getAllStations = (getAllStationsHandler) =>
  db.ref(stationsPath).on("value", (snap) => getAllStationsHandler(snap.val()));

export const getStation = (id, getStationHandler) =>
  id && id !== "new"
    ? db
        .ref(`${stationsPath}/${id}`)
        .on("value", (snap) => getStationHandler(snap.val()))
    : getStationHandler(false);

export const getAllLanguages = () =>
  db
    .ref("/languages")
    .once("value")
    .then((snap) =>
      Object.values(snap.val())
        .filter((lang) => lang.active)
        .sort((a, b) => {
          if (a.engName > b.engName) return 1;
          if (a.engName < b.engName) return -1;
          return 0;
        })
        .map((lang) => {
          const { id, engName } = lang;
          return { key: id, value: id, text: engName };
        })
    );

export const getAllMusicGenres = () =>
  db
    .ref("/music-genres")
    .once("value")
    .then((snap) =>
      Object.values(snap.val()).map((genre) => {
        const { id, name } = genre;
        return { key: id, value: id, text: name };
      })
    );

export const createNewStation = (data) => {
  const newStationRef = db.ref(stationsPath).push();
  newStationRef.set(data);
  return newStationRef.key;
};

export const updateStation = (stationId, updates) =>
  db.ref(`${stationsPath}/${stationId}`).update(updates);

export const addNewStation = () => db.ref(``);

export const deleteStation = (stationId) =>
  db.ref(`${stationsPath}/${stationId}`).remove();
