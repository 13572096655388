import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Segment, Message } from "semantic-ui-react";

import { db } from "../../firebase";
import MusicAdminEditMenu from "./MusicAdminEditMenu";
import StationStatus from "./StationStatus";
import StationEditForm from "./StationEditForm";
import StationStreams from "./StationStreams";

const initialStationState = {
  active: false,
  description: "",
  genres: [],
  languageId: 0,
  logo: "",
  name: "",
  streams: [],
  website: "",
};

const StationEdit = ({ match }) => {
  const { params } = match;
  const { id } = params;
  const [languages, setLanguages] = useState({ loading: true, data: [] });
  const [genres, setGenres] = useState({ loading: true, data: [] });
  const [stationData, setStationData] = useState({
    loading: true,
    ...initialStationState,
  });
  const [incompleteAlert, setIncompleteAlert] = useState(false);
  const [activationToggleError, setActivationToggleError] = useState("");

  const getStationDataHandler = (data) =>
    data
      ? setStationData({ loading: false, ...data })
      : setStationData({ loading: false, ...initialStationState });

  useEffect(() => {
    db.getAllLanguages().then((data) => {
      setLanguages({ loading: false, data });
    });
    db.getAllMusicGenres().then((data) => {
      setGenres({ loading: false, data });
    });
    db.getStation(id, getStationDataHandler);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStationData({ ...stationData, [name]: value });
  };

  const handleLanguageSelection = (e, { value }) => {
    setStationData({ ...stationData, languageId: value });
  };

  const handleGenreSelection = (e, { value }) => {
    setStationData({ ...stationData, genres: value });
  };

  const handleSourceInputChange = (index, type, value) => {
    let updatedStreams = stationData.streams.slice();
    updatedStreams[index][type] = value;
    setStationData({
      ...stationData,
      streams: updatedStreams,
    });
  };

  const handleRemoveSource = (index) => {
    let updatedStreams = stationData.streams.slice();
    updatedStreams.splice(index, 1);
    console.log("new streams: ", updatedStreams);
    setStationData({
      ...stationData,
      streams: updatedStreams,
    });
    console.log("remove stream number ", index);
  };

  const handleAddSource = () => {
    let updatedStreams = stationData.streams;
    updatedStreams.push({ src: "", type: "" });
    setStationData({ ...stationData, streams: updatedStreams });
  };

  const handleActivationToggle = (event) => {
    if (
      stationData.active ||
      (name &&
        description &&
        languageId &&
        stationData.genres.length > 0 &&
        website &&
        logo)
    ) {
      const newData = { ...stationData, active: !stationData.active };
      setStationData(newData);
      setActivationToggleError("");
      db.updateStation(id, newData);
    } else {
      setActivationToggleError(
        "This station cannot be activated until all fields are filled out"
      );
    }
  };

  const handleNewSave = () => {
    if (
      name &&
      description &&
      languageId &&
      stationData.genres.length > 0 &&
      website &&
      logo
    ) {
      db.createNewStation(stationData);
      window.location.href = "/music-admin"; // This seems to be a slow solution. A React redirect would be better but it's not working for some reason
    } else {
      setIncompleteAlert(true);
    }
  };

  const handleSave = () => {
    console.log(stationData);
    db.updateStation(id, stationData);
  };

  const handleDeleteStation = () => {
    const areYouSure = window.confirm(
      "Are you sure you want to delete this station?"
    );
    if (areYouSure) {
      db.deleteStation(id);
      window.location.href = "/music-admin";
    }
  };

  const {
    name,
    description,
    languageId,
    website,
    logo,
    active,
    streams,
  } = stationData;

  return (
    <Segment>
      <MusicAdminEditMenu
        id={id}
        name={name || "New Station"}
        handleNewSave={handleNewSave}
        handleDeleteStation={handleDeleteStation}
      />
      {id !== "new" && (
        <StationStatus
          active={active}
          handleActivationToggle={handleActivationToggle}
          activationToggleError={activationToggleError}
        />
      )}
      {incompleteAlert === true && (
        <Message
          negative
          header="Warning - Cannot Submit"
          content="The station data section must be completed before saving."
          onDismiss={() => setIncompleteAlert(false)}
        />
      )}
      <StationEditForm
        languages={languages}
        id={id}
        allGenres={genres}
        stationData={stationData}
        handleInputChange={handleInputChange}
        handleLanguageSelection={handleLanguageSelection}
        handleGenreSelection={handleGenreSelection}
        handleSave={handleSave}
      />
      <StationStreams
        id={id}
        streams={streams}
        handleSourceInputChange={handleSourceInputChange}
        handleRemoveSource={handleRemoveSource}
        handleAddSource={handleAddSource}
        handleSave={handleSave}
      />
    </Segment>
  );
};

export default StationEdit;
