import React, { useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";

import { auth } from "../../firebase";

const PasswordChangeForm = () => {
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === "passwordOne") setPasswordOne(value);
    if (name === "passwordTwo") setPasswordTwo(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    auth
      .updatePassword(passwordOne)
      .then(() => {
        setPasswordOne("");
        setPasswordTwo("");
        setError("");
        setMessage("Password has been successfully updated");
      })
      .catch((error) => setError(error.message));
  };

  const isInvalid = passwordOne === "" || passwordOne !== passwordTwo;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          value={passwordOne}
          name="passwordOne"
          onChange={(event) => handleChange(event)}
          placeholder="New Password"
          type="password"
          icon="lock"
          iconPosition="left"
        />
        <Form.Input
          value={passwordTwo}
          name="passwordTwo"
          onChange={(event) => handleChange(event)}
          placeholder="Confirm New Password"
          type="password"
          icon="lock"
          iconPosition="left"
        />
        <Button
          disabled={isInvalid}
          type="submit"
          color="orange"
          content="Update&nbsp;Password"
        />
      </Form.Group>
      <Message negative hidden={!error} content={error} />
      <Message positive hidden={!message} content={message} />
    </Form>
  );
};

export default PasswordChangeForm;
