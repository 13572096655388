import React from "react";
import { Segment, List } from "semantic-ui-react";

import MusicAdminMenu from "./MusicAdminMenu";
import StationListItem from "./StationListItem";

const MusicAdminList = ({ active, handleChange, stations, searchTerm }) => (
  <>
    <MusicAdminMenu
      active={active}
      handleChange={handleChange}
      searchTerm={searchTerm}
    />
    <Segment basic padded loading={!active}>
      <List divided relaxed>
        {active
          ? Object.keys(stations)
              .filter((id) =>
                stations[id].name
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              )
              .sort((a, b) => (stations[a].name > stations[b].name ? 1 : 0))
              .map((id) => (
                <StationListItem
                  placeholder={false}
                  id={id}
                  station={stations[id]}
                  key={id}
                />
              ))
          : [...Array(24)].map((e, i) => (
              <StationListItem placeholder key={`key-${i}`} />
            ))}
      </List>
    </Segment>
  </>
);

export default MusicAdminList;
