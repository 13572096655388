import React from "react";
import { Segment, Menu, Button, Form, Input, List } from "semantic-ui-react";

const StationStreams = ({
  id,
  streams,
  handleRemoveSource,
  handleAddSource,
  handleSourceInputChange,
  handleSave,
}) => {
  return (
    <Segment>
      <Menu secondary>
        <Menu.Header as="h3" content="Station Stream Sources" />
      </Menu>
      <Form>
        <List>
          {streams &&
            streams.length > 0 &&
            streams.map((stream, index) => (
              <List.Item key={`key-${index}`}>
                <Form.Group inline>
                  <Form.Field
                    control={Input}
                    onChange={({ target }) =>
                      handleSourceInputChange(index, "src", target.value)
                    }
                    label="Source"
                    placeholder="https://..."
                    name="src"
                    value={stream.src}
                  />
                  <Form.Field
                    control={Input}
                    onChange={({ target }) =>
                      handleSourceInputChange(index, "type", target.value)
                    }
                    label="Type"
                    placeholder="audio/mpeg"
                    name="type"
                    value={stream.type}
                  />
                  <Form.Button
                    basic
                    icon="times"
                    position="right"
                    onClick={() => handleRemoveSource(index)}
                  />
                </Form.Group>
              </List.Item>
            ))}
          <Button.Group vertical>
            <Button
              icon="plus"
              basic
              content="New Source"
              onClick={handleAddSource}
            />
            {id !== "new" && (
              <Button
                icon="save"
                basic
                content="Save Sources"
                color="orange"
                onClick={handleSave}
              />
            )}
          </Button.Group>
        </List>
      </Form>
    </Segment>
  );
};

export default StationStreams;
