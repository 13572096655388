import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import paths from "../../paths.json";

const SiteMenu = () => (
  <Dropdown item icon="bars">
    <Dropdown.Menu>
      <Dropdown.Item as={Link} to={paths.home}>
        <Icon name="home" />
        Home
      </Dropdown.Item>
      <Dropdown.Item as={Link} to={paths.musicAdmin}>
        <Icon name="music" />
        Music & Radio
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default SiteMenu;
