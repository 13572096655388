import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";

import UserProvider from "./providers/UserProvider";
import SiteHeader from "./components/SiteHeader";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
import Footer from "./components/Footer";

const App = () => (
  <BrowserRouter>
    <UserProvider>
      <Container fluid id="body-container">
        <SiteHeader />
        <Container id="content">
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Container>
        <Footer />
      </Container>
    </UserProvider>
  </BrowserRouter>
);

export default App;
