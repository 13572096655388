import React from "react";
import { List, Button, Placeholder } from "semantic-ui-react";
import { Link } from "react-router-dom";

import paths from "../../paths.json";

const StationListItem = ({ placeholder, id, station }) => (
  <List.Item>
    <List.Content floated="right">
      <Button
        content="Edit"
        icon="pencil"
        color="orange"
        compact
        basic
        disabled={placeholder}
        as={Link}
        to={`${paths.musicAdmin}/edit/${id}`}
      />
    </List.Content>
    {placeholder ? (
      <List.Icon name="microphone slash" color="grey" />
    ) : (
      <List.Icon
        name={station.active ? "microphone" : "microphone slash"}
        color={station.active ? "green" : "red"}
      />
    )}
    <List.Content>
      {!placeholder ? (
        <List.Header content={station.name} />
      ) : (
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      )}
    </List.Content>
  </List.Item>
);

export default StationListItem;
