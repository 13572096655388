import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";

import paths from "../../paths.json";

const UserMenu = () => (
  <Dropdown item icon="user" direction="left">
    <Dropdown.Menu>
      <Dropdown.Item as={Link} to={paths.account}>
        <Icon name="user" />
        Account
      </Dropdown.Item>

      <Dropdown.Item onClick={auth.signOut}>
        <Icon name="lock" /> Sign Out
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default UserMenu;
