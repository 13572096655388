import React from "react";
import { Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { string } from "prop-types";

import paths from "./../paths.json";

const NotFound = () => (
  <Message>
    <h1>404</h1>
    <p>This page cannot be found.</p>
    <Link to={paths.home}>Return to Home</Link>
  </Message>
);

NotFound.propTypes = {
  home: string.isRequired,
};

export default NotFound;
