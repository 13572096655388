import React from "react";
import { Menu, Header, Button, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";

import paths from "../../paths.json";

const MusicAdminMenu = ({ active, handleChange, searchTerm }) =>
  active && (
    <Menu secondary stackable>
      <Menu.Item>
        <Header as="h2" color="blue" content="Music & Radio Stations" />
      </Menu.Item>
      <Menu.Item>
        <Button
          content="New"
          icon="plus"
          color="orange"
          as={Link}
          to={`${paths.musicAdmin}/edit/new`}
        />
      </Menu.Item>
      <Menu.Item position="right">
        <Input
          style={{ marginLeft: 12 }}
          icon="search"
          placeholder="Search..."
          onChange={handleChange}
          value={searchTerm}
        />
      </Menu.Item>
    </Menu>
  );

export default MusicAdminMenu;
