import React, { useState } from "react";
import { Grid, Segment, Form, Button, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { auth } from "./../firebase";
import paths from "./../paths.json";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "email") setEmail(value);
  };

  const sendResetEmail = (event) => {
    event.preventDefault();
    auth
      .passwordReset(email)
      .then(() => setEmailIsSent(true))
      .catch(() => setError("Error resetting password"));
  };

  return (
    <Grid textAlign="center">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          <Form size="large" onSubmit={sendResetEmail}>
            <Form.Input
              icon="mail"
              value={email}
              name="email"
              onChange={(event) => handleChange(event)}
              placeholder="Email Address"
              type="email"
              iconPosition="left"
            />
            <Button
              type="submit"
              color="orange"
              fluid
              size="large"
              disabled={email.length === 0}
            >
              Reset My Password
            </Button>
            <Button
              style={{ marginTop: "1em" }}
              fluid
              basic
              as={Link}
              to={`${paths.login}`}
              content="Back to Login"
            />
            {emailIsSent && (
              <Message positive content="Password Reset Email Has Been Sent" />
            )}
            {error && <Message negative>{error}</Message>}
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default ResetPassword;
