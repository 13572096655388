import React from "react";
import { Message } from "semantic-ui-react";

const PermissionsError = () => (
  <Message
    warning
    header="Invalid Permissions"
    content={
      <p>
        You do not have permission to access this site. If you believe this is
        an error, please contact{" "}
        <a href="mailto: twr360admin@twr.org">twr360admin@twr.org</a>
      </p>
    }
  ></Message>
);

export default PermissionsError;
