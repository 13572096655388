import React from "react";
import { Segment, Container, List } from "semantic-ui-react";

const Footer = () => (
  <Segment as="footer" id="site-footer" inverted>
    <Container textAlign="right">
      <List link divided inverted horizontal>
        <List.Item
          as="a"
          href="https://www.twr.org"
        >{`© ${new Date().getFullYear()} TWR`}</List.Item>
        <List.Item as="a" href="https://www.twr.org/contact-us">
          Contact Us
        </List.Item>
        <List.Item as="a" href="https://www.twr.org/policies">
          Policies
        </List.Item>
      </List>
    </Container>
  </Segment>
);

export default Footer;
