import React, { useState, useEffect } from "react";
import { Segment } from "semantic-ui-react";

import { db } from "../../firebase";
import MusicAdminList from "./MusicAdminList";

const MusicAdmin = () => {
  const [stationsQuery, updateStationsQuery] = useState({
    loading: true,
    stations: null,
  });
  const [searchTerm, updateSearchTerm] = useState("");
  const { loading, stations } = stationsQuery;

  const handleChange = (event, data) => updateSearchTerm(data.value);

  const getAllStationsHandler = (stations) =>
    updateStationsQuery({ loading: false, stations });

  useEffect(() => {
    db.getAllStations(getAllStationsHandler);
  }, []);

  return (
    <Segment>
      <MusicAdminList
        active={!loading}
        handleChange={handleChange}
        stations={stations}
        searchTerm={searchTerm}
      />
    </Segment>
  );
};

export default MusicAdmin;
