import React from "react";
import { Header, Segment } from "semantic-ui-react";

// import BasicHeader from '../../components/BasicHeader';
import PasswordChangeForm from "./PasswordChangeForm";

const Account = () => (
  <Segment>
    <Header as="h2" content="Account" color="blue" />
    <Header as="h3" content="Update Password" />
    <PasswordChangeForm />
  </Segment>
);

export default Account;
