import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, Container } from "semantic-ui-react";

import { UserContext } from "../../providers/UserProvider";
import paths from "../../paths.json";
import SiteMenu from "./SiteMenu";
import UserMenu from "./UserMenu";

const SiteHeader = () => {
  const { user } = useContext(UserContext);
  return (
    <Menu borderless fixed="top" inverted id="site-header">
      <Container id="menu-container">
        <Menu.Item as={Link} to={paths.home}>
          TWR360 Admin
        </Menu.Item>
        {user && (
          <Menu.Menu position="right">
            <UserMenu />
            <SiteMenu />
          </Menu.Menu>
        )}
      </Container>
    </Menu>
  );
};

export default SiteHeader;
