import React from "react";
import { Card } from "semantic-ui-react";
import { Link } from "react-router-dom";

import paths from "../../paths.json";

const tools = [
  {
    header: "Music & Radio Admin",
    description: "Tools to manage the TWR360 Music & Radio page",
    as: Link,
    to: paths.musicAdmin,
  },
{
    header: "Landing Page Admin",
    description: "Tools to manage the TWR360 landing pages",
    href: paths.strapiAdmin,
    target:"_blank"
  },
  {
    header: "CCIM Admin",
    description: "Tools to manage the TWR360 CCIM page",
    href: paths.ccimAdmin,
    target:"_blank"
  },
];

const AdminToolsList = () => <Card.Group items={tools} />;

export default AdminToolsList;
