import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "../components/PrivateRoute";
import paths from "./../paths.json";
import Home from "./Home";
import Account from "./Account";
import MusicAdmin from "./MusicAdmin/MusicAdmin";
import StationEdit from "./StationEdit";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";

const Routes = () => (
  <Switch>
    <PrivateRoute exact path={paths.home} component={Home} />
    <PrivateRoute exact path={paths.account} component={Account} />
    <PrivateRoute exact path={paths.musicAdmin} component={MusicAdmin} />
    <PrivateRoute
      exact
      path={`${paths.musicAdmin}/edit/:id`}
      component={StationEdit}
    />
    <Route path={paths.login} component={Login} />
    <Route path={paths.resetPassword} component={ResetPassword} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
