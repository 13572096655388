import React, { Component } from "react";
import { Message } from "semantic-ui-react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Message negative>
          <Message.Header>Something has gone wrong</Message.Header>
          <p>
            Try refreshing the page. If that does not resolve your error, please
            contact{" "}
            <a href="mailto:twr360admin@twr.org?subject=Error with TWR360 Admin Webapp">
              twr360admin@twr.org
            </a>
          </p>
        </Message>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
