import React from "react";
import { Segment, Header, Checkbox, Message } from "semantic-ui-react";

const StationStatus = ({
  active,
  handleActivationToggle,
  activationToggleError,
}) => (
  <Segment>
    <Header as="h3" content="Station Status" />
    <Checkbox
      label={active ? "Activated" : "Deactivated"}
      checked={active}
      toggle
      name="active"
      onChange={handleActivationToggle}
    />
    <Message
      error
      hidden={!activationToggleError}
      content={activationToggleError}
    />
  </Segment>
);

export default StationStatus;
